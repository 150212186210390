import React from "react";
import {connect} from "react-redux";
import {logoutUser} from "../../actions";


class AdminMenu extends React.Component {
    handleLogout = () => {
        const {dispatch} = this.props;
        dispatch(logoutUser());
    };

    active = val => {
        const check = window.location.pathname.includes(val);
        if (check) {
            return {color: '#137509', active: 'active'}
        }else{
            return {color: ''}
        }
    }

    set_active() {
        const active = document.querySelector("#nav").firstElementChild.children;
        for (const isActive of active) {
            isActive.style.width = (100/active.length).toString()+'%';
            if(isActive.firstElementChild.getAttribute('href').includes(window.location.pathname)){
                isActive.setAttribute('class','active')
            }
        }
    }

    componentDidMount() {
        try {
            this.set_active()
        } catch (e) {

        }
    }

    render() {
        const {isLoggingOut, logoutError} = this.props;
        return (
            <div id="nav">
                <ul>
                    <li style={{background: this.active('status').color}}><a
                        href="/admin/status"><span>Status</span></a></li>
                    <li style={{background: this.active('posts').color}}><a href="/admin/posts"><span>Posts</span></a>
                    </li>
                    <li style={{background: this.active('push').color}}><a href="/admin/push"><span>Push</span></a></li>
                    <li style={{background: this.active('email').color}}><a href="/admin/email"><span>Email</span></a></li>
                    <li style={{background: this.active('traffic').color}}><a href="/admin/traffic"><span>Traffic</span></a>
                    </li>
                    <li style={{background: this.active('social').color}}><a
                        href="/admin/social"><span>Social</span></a></li>
                    <li style={{background: this.active('files').color}}><a href="/admin/files"><span>Files</span></a>
                    </li>
                    <li style={{background: this.active('plan').color}}><a href="/admin/plan"><span>Plan</span></a></li>
                </ul>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError
    };
}

export default connect(mapStateToProps)(AdminMenu);

