import React from "react";
import ClickHandler2 from './ClickHandler2';
function RegularPost2(e, query, page) {

    return(
        <div className='card'>
            <a
                 href={`#`}
                 onClick={() => ClickHandler2(e, query)}>
                <div className='card-body'>
                    <div className='img-container'
                         style={{backgroundImage: `url(${e.image}`}}
                    />
                    <h5 className='card-title'>
                        {e.title}
                        <section className='arrow'>more</section>
                    </h5>
                    {/*<section className='views'>{e.views}</section>*/}
                    { page === 'website' && renderDate(e) }
                </div>
            </a>
        </div>
    )
}
function renderDate(e){
    let date = e.date !== 0 ? new Date(e.date*1000).toLocaleDateString() : '-';
    return (
        <section className='date'>{date}</section>
    )
}
export default RegularPost2;
