import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import {FETCH_PATH} from "../constants";
import {Header, Footer, RegularPost, FirstPost} from "./";
import {shuffle} from "../actions";

let container;
export default class PostRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: null,
            nextPage: 1,
            query: null,
            tracker: '',
            bait: false
        };
    }

    async loadData() {
        let u = ''
        await window.addEventListener('scroll', this.handleScroll, true);
        if (this.state.nextPage !== null) {
            if (window.location.pathname === '/' || window.location.host.includes('localhost')) {
                u = "https://foreshop.net/src/clickbait.json";
                this.setState({bait: true})
            } else {
                u = this.props.name
                    ? FETCH_PATH + this.props.fetchURL + this.props.name.match.params.name + '/' + this.state.nextPage
                    : FETCH_PATH + this.props.fetchURL + this.state.nextPage;
            }
            let query = window.location.href.split("?");
            await fetch(u)
                .then(response => response.json())
                .then(data => {
                    data.posts = shuffle(data.posts)
                    if (this.state.posts === null) {
                        if (query.length === 2) {
                            this.setState({posts: data.posts, nextPage: data.next, query: query[1]});
                        } else {
                            this.setState({posts: data.posts, nextPage: data.next});
                        }
                    } else {
                        if (query.length === 2) {
                            this.setState({
                                posts: this.state.posts.concat(data.posts),
                                nextPage: data.next,
                                query: query[1]
                            });
                        } else {
                            this.setState({
                                posts: this.state.posts.concat(data.posts),
                                nextPage: data.next
                            });
                        }
                    }
                })
                .catch(err => console.error(this.props.url, err.toString()));
            await window.addEventListener('scroll', this.handleScroll, true);
        }
    }

    componentDidMount() {
        container = document.getElementById('container');
        this.loadData()
    }

    handleScroll = () => {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if (this.state.nextPage && windowHeight + window.scrollY > container.clientHeight) {
            this.loadData()
        }
    };

    render() {
        let query = this.state.query ? '?'.concat(this.state.query) : '';
        return (
            <div>
                <Header/>
                <Container fluid id="container">
                    <Row>
                        {this.state.posts && this.state.posts.map((e, i) => {
                            let render = i === 0 ? FirstPost(e, query, this.props.page) : RegularPost(e, query, this.props.page);
                            let colProps = i === 0 ? {sm: 6, lg: 4, xl: 4} : {sm: 3, lg: 2, xl: 2};
                            return (
                                <Col sm={colProps.sm} lg={colProps.lg} xl={colProps.xl} key={i}>
                                    {render}
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
    }
}
