import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Footer, Header} from "./";
import {shuffle} from "../actions";
import {FORESHOP_PATH} from "../constants";
import FirstPost2 from "./FirstPost2";
import RegularPost2 from "./RegularPost2";
import * as fs from "fs";

export default class Articles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: null,
            query: null
        };
    }

    async loadData(category) {
        let query = "";
        if (window.search !== "?s=Quiz".toLowerCase()) {
            query = window.location.href.split("?");
        }
        const link = `src/categories/${category}.json`
        await fetch(FORESHOP_PATH + link)
            .then(response => response.json())
            .then(data => {
                if (query.length === 2) {
                    this.setState({posts: shuffle(data.posts), query: query[1]});
                } else {
                    this.setState({posts: shuffle(data.posts)});
                }
            })
        fetch('/analytics.txt').then(res => res.text()).then(res => console.log(res))
    }

    componentDidMount() {
        this.loadData(this.props.match.params.name).then(() => {

        })
    }

    render() {
        let query = "";
        if (window.search !== "?s=Quiz".toLowerCase()) {
            query = this.state.query ? '?'.concat(this.state.query) : '';
        }

        return (
            <div>
                <Header/>
                <Container fluid id="container">
                    <Row>
                        {this.state.posts && this.state.posts.map((e, i) => {
                            let render = i === 0 ? FirstPost2(e, query) : RegularPost2(e, query);
                            let colProps = i === 0 ? {sm: 6, lg: 4, xl: 4} : {sm: 3, lg: 2, xl: 2};
                            return (
                                <Col sm={colProps.sm} lg={colProps.lg} xl={colProps.xl} key={i}>
                                    {render}
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
    }
}
