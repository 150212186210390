import {PUSH_PATH} from "../constants/";

export default function Push() {
    const applicationServerPublicKey = 'BPPH4s3CiFR7SZneJXJlnOfDqrfXLrk3HGfLHu5lcbYnl03rl5VPrHyKl6J9tGW32lOhZn6hmSi3q4n5zeXOKlQ';

    let isSubscribed = false;
    let swRegistration = null;

    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            // eslint-disable-next-line no-useless-escape
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    function initializeUI() {
        // Set the initial subscription value
        swRegistration.pushManager.getSubscription()
            .then(function (subscription) {
                isSubscribed = !(subscription === null);

                if (isSubscribed) {
                    console.log('User IS subscribed.');
                } else {
                    console.log('User is NOT subscribed.');
                    subscribeUser();
                }
            });
    }

    function subscribeUser() {
        const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
        swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
            .then(function (subscription) {
                console.log('User is subscribed.');

                sendSubscriptionToBackEnd(subscription, 'subscribe');

                isSubscribed = true;
            })
            .catch(function (err) {
                console.log('Failed to subscribe the user: ', err);
            });
    }

    function sendSubscriptionToBackEnd(subscription, action) {
        let data = JSON.stringify(subscription)
        data = JSON.parse(data);
        data.domain = window.location.hostname;
        data.action = action;
        data = JSON.stringify(data);
        return fetch(PUSH_PATH+'v1/subscribers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error('Bad status code from server.');
                }
                return response.json();
            })
            .then(function (responseData) {
                if (!(responseData.data && responseData.data.success)) {
                    throw new Error('Bad response from server.');
                }
            });
    }
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push is supported');
        navigator.serviceWorker.register('../../src/serviceWorker.js?v=1')
            .then(function (swReg) {
                console.log('Service Worker is registered', swReg);
                swRegistration = swReg;
                initializeUI();
            })
            .catch(function (error) {
                console.error('Service Worker Error', error);
            });
    } else {
        console.warn('Push messaging is not supported');
    }
}
