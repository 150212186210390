import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import configureStore from "./configureStore";
import { Push } from "./utils"
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        {Push()}
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
