import React from "react";
import {IMAGES_PATH} from "../constants";
import {ClickHandler} from "./";

function FirstPost(e, query, page, data) {
    console.log(data)
    return (
        <div id='first-card' className='card'>
            <a
                href={e.url?? `/post/${e.slug}${query}`}
                onClick={() => ClickHandler(e, query)}
            >
                <div className='card-body'>
                    <div className='img-container'
                         style={{backgroundSize: e.image? '70%':'',backgroundImage: e.image ? `url(${e.image})` : `url(${IMAGES_PATH}640/${e.website}-${e.slug}.jpg)`}}
                    />
                    <div className="card-title">
                        <h5>
                            {e.title}
                        </h5>
                    </div>
                    <div className="button">Read More</div>
                    {/*<section className='views'>{e.views}</section>*/}
                    {page === 'website' && renderDate(e)}
                </div>
            </a>
        </div>
    )
}

function renderDate(e) {
    let date = e.date !== 0 ? new Date(e.date * 1000).toLocaleDateString() : '-';
    return (
        <section className='date'>{date}</section>
    )
}

export default FirstPost;
