import React from "react";
import {Col, Table} from "react-bootstrap";
import { FETCH_PATH } from "../../constants";

class Status extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            websitesStatus:[],
            posts: 0,
            fromDate: 0,
            toDate: 0,
            timeOptions: {day: '2-digit',month: '2-digit',year: 'numeric',hour: 'numeric', minute: 'numeric'}
        };
    }
    loadData() {
        fetch(`${FETCH_PATH}admin/website-statuses`)
            .then(response => response.json())
            .then(data => {
                data.websites.sort((a,b) => {
                    const one = a?.statusHistory[0]?.added
                    const two = b?.statusHistory[0]?.added
                    if(one && two){
                        const d1 = new Date(one);
                        const d2 = new Date(two)
                        const is_equal = d1 === d2
                        if(is_equal){
                            return 0
                        }
                        return d1 > d2? -1 : 1;
                    }else if(!one && !two){
                        return 0;
                    }else{
                        if(typeof two === "undefined"){
                            return -1
                        }else{
                            return 1
                        }
                    }


                })
                this.setState({websitesStatus: data.websites});

            })
            .then(() => this.load_cats())
            .catch(err => console.error(this.props.url, err.toString()));
    }

    load_cats(){
        fetch('/src/IAB-Categories.json')
            .then(response => response.json())
            .then(data => {
                this.setState({cat: data.data});
            })
            .catch(err => console.error(this.props.url, err.toString()));
    }
    componentDidMount() {
        document.title = "ForeShop | Dashboard";
        this.loadData()
    }

    renderData(){
        return(
            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Domain</th>
                    <th>Previous Status</th>
                    <th>Current Status</th>
                    <th>Status Changed</th>
                    <th>Date Added</th>
                </tr>
                </thead>
                <tbody>
                {this.state.websitesStatus.map((e, i) => {
                    const is_history_available = e['statusHistory'].length > 1
                    let history;
                    try{
                        history = is_history_available? e['statusHistory'][1]['status'] : "NO HISTORY"
                    }catch(err){
                        history = "NO HISTORY"
                    }

                    return(
                        <tr key={i} >
                            <td>{i+1}</td>
                            <td><a href={`/website/${e.domain}`} target="_blank" rel="noopener noreferrer">{e.domain}</a></td>
                            <td className={is_history_available? statusClass(history) : "text-warning"}><small>{history}</small></td>
                            <td className={statusClass(e.status)}><small>{e.status}</small></td>
                            {(e.statusHistory.length>0)?
                                <td>{new Date(e.statusHistory[0].added).toLocaleDateString("he-IL",this.state.timeOptions).replace(/\./gm,'/')}</td>
                            :
                                <td>-</td>
                            }
                            <td>{new Date(e.added).toLocaleDateString("he-IL",this.state.timeOptions).replace(/\./gm,'/')}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )
    }
    render() {
        return(
            <Col>
                {this.state.websitesStatus && this.renderData()}
            </Col>
        );
    }
}
function dateToStr(date){
    return date !== 0 ? new Date(date*1000).toLocaleDateString() : '-';
}
function statusClass(data) {
    return (data === "CLIENT_ACTIVE" || data==="CUSTOMER_ACTIVE" || data === "COMPANY_ACTIVE" )? 'text-success' : 'text-danger'
}
export default Status;
