import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBMgXC9PTgSRLzuwYL3K8U5MqxzTwX4w1I",
    authDomain: "leads-foreshop.firebaseapp.com",
    databaseURL: "https://leads-foreshop.firebaseio.com",
    projectId: "leads-foreshop",
    storageBucket: "leads-foreshop.appspot.com",
    messagingSenderId: "395814430547",
    appId: "1:395814430547:web:8d559bd959ae408096e3a6",
    measurementId: "G-77WKDLG1YW"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
