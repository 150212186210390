import React from "react";
import {Container, Row, Col, Navbar, Nav, NavDropdown, Form, Button, NavItem, NavLink} from 'react-bootstrap';
import logo from '../assets/images/ForeShopGroup.png';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            searchText: '',
            available_categories: ['dogs','alternative_medicine','environmental_safety','internet_technology','marketing','phones','portable']
        };
    }
    handleSearchInput = e => {
        this.setState({
            searchText: e.target.value
        });
    };

    navigate(link){
        window.location.pathname = link;
    }

    async loadData() {
        if (localStorage.hasOwnProperty('nav')) {
            let value = JSON.parse(localStorage.getItem('nav'));
            this.setState({data : value})
        } else {
            await fetch('/src/header.json')
                .then(response => response.json())
                .then(d => {
                    this._isMounted && this.setState({data: d});
                    localStorage.setItem('nav', JSON.stringify(d));
                })
                .catch(err => console.error(this.props.url, err.toString()))
        }
    }

    componentDidMount() {
        this.loadData()
    }
    renderNav(nav){
        return(
            <Nav className="mr-auto">
                {nav.map((e, i) => {
                    const formatted = e.replace('_',' ').toUpperCase();
                    return (
                        <NavItem as={Nav.Link} onClick={() => this.navigate(`/articles/${e}`)} title={e} id={`nav-dropdown-${i}`} key={i}>
                            <b>{formatted}</b>
                        </NavItem>
                    );
                })}
            </Nav>
        );
    }

    render() {
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/">
                    <img
                        alt='ForeShopGroup'
                        src={logo}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse>
                    {this.state.data && this.renderNav(this.state.available_categories)}
                    <Form
                        action={`/search/${this.state.searchText}`}
                        className="d-flex my-2"
                    >
                        <Form.Control
                            required
                            onChange={this.handleSearchInput}
                            value={this.state.searchText}
                            type="text"
                            placeholder="Search..."
                        />
                        <Button
                            type="submit"
                            variant="outline-success"
                        >
                            Search
                        </Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
