import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "../actions";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Header, Footer } from "./";

class Login extends Component {
    state = { email: "", password: "" };

    handleEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { email, password } = this.state;

        dispatch(loginUser(email, password));
    };

    render() {
        const { loginError, isAuthenticated } = this.props;
        if (isAuthenticated) {
            return <Redirect to="/admin" />;
        } else {
            document.title = "ForeShop | Log in";
            return (
                <div>
                    <Header/>
                    <Container>
                        <Row>
                            <Col xs={12} md={4} sm={3} className="mx-auto my-3">
                                <h3 className="text-center">Sign In</h3>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter Email"
                                            name="email"
                                            onChange={this.handleEmailChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            onChange={this.handlePasswordChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Keep me signed in." />
                                    </Form.Group>
                                    {loginError && (
                                        <p>Incorrect email or password.</p>
                                    )}
                                    <Button
                                        variant="primary"
                                        type="button"
                                        className="btn-block"
                                        onClick={this.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <Footer/>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated
    };
}

export default connect(mapStateToProps)(Login);
