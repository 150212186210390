import React from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { Login, PostRenderer, ProtectedRoute, NotFound, Top, WebsiteRandom, Admin, Quiz, Articles} from "./components";
import { GoogleAnalytics } from './utils'

function App(props) {
    const { isAuthenticated, isVerifying } = props;

    return (
        <Router>
            { GoogleAnalytics.init() && <GoogleAnalytics.RouteTracker /> }
            <Switch>
                <Route path={['/', '/home']} exact component={() => <PostRenderer fetchURL={'posts/'}/>} />
                <Route path={'/top'} exact component={Top} />
                <Route path={['/quiz','/store/','/category/trending/']} exact component={Quiz} />
                <Route path={'/test/'} exact render={() => <div></div>} />
                <Route path={['/articles/:name']} exact component={Articles} />
                <Route path={'/?s=quiz'} exact component={Quiz} />
                <Route path={'/post/:name'} component={(props) => <PostRenderer fetchURL={'post/'} name={props}/>}/>
                <Route path={'/category/:name'} component={(props) => <PostRenderer fetchURL={'category/'} name={props}/>}/>
                <Route path={'/website/:name'} component={(props) => <PostRenderer fetchURL={'website/'} name={props} page='website'/>}/>
                <Route path={'/top/website/:name'} component={(props) => <WebsiteRandom fetchURL={'website/'} name={props}/>}/>
                <Route path={'/search/:name'} component={(props) => <PostRenderer fetchURL={'search/'} name={props}/>}/>
                <ProtectedRoute
                    exact
                    path={['/admin','/admin/posts']}
                    component={() => Admin('Posts')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/status"
                    component={() => Admin('Status')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/traffic"
                    component={() => Admin('Traffic')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/push"
                    component={() => Admin('Push')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/email"
                    component={() => Admin('Email')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/social"
                    component={() => Admin('Social Media')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/files"
                    component={() => Admin('Files')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    exact
                    path="/admin/plan"
                    component={() => Admin('Plan')}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <Route path="/login" component={Login} />
                <Route component={NotFound}/>
            </Switch>
        </Router>
    );
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying
    };
}

export default connect(mapStateToProps)(App);
