import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import { Header, Footer, FirstPost, RegularPost } from "./";
import { shuffle } from "../actions";
import { FORESHOP_PATH } from "../constants";
export default class Top extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            posts: null,
            query: null
        };
    }
    async loadData() {
        let query = window.location.href.split("?");
        await fetch(FORESHOP_PATH+'src/longposts.json')
            .then(response => response.json())
            .then(data => {
                if (query.length === 2){
                    this.setState({posts: shuffle(data.posts), query: query[1]});
                } else {
                    this.setState({posts: shuffle(data.posts)});
                }
            })
    }
    componentDidMount() {
        this.loadData()
    }

    render() {
        let query = this.state.query ? '?'.concat(this.state.query) : '';
        return(
            <div>
                <Header/>
                <Container fluid id="container">
                    <Row>
                        {this.state.posts && this.state.posts.map((e, i) => {
                            let render = i === 0 ? FirstPost(e, query) : RegularPost(e, query);
                            let colProps = i === 0 ? {sm: 6, lg: 4, xl: 4} : {sm: 3, lg: 2, xl: 2};
                            return(  
                                <Col sm={colProps.sm} lg={colProps.lg} xl={colProps.xl} key={i}>
                                    {render}
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
    }
}
