import React from "react";
import {Col, Form, Table} from "react-bootstrap";
import {FETCH_PATH} from "../../constants";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class Traffic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            min3days: 0,
            minThisMonth: 0,
            minLastMonth: 0,
            max3days: 999999999,
            maxThisMonth: 999999999,
            maxLastMonth: 999999999,
            noTop50Post: false,
            withTop50Post: false,
            statusBursa: false,
            statusCompanyActive: false,
            statusClientActive: false,
            statusCompanyInactive: false,
            statusOnPause: false,
            statusPaymentIssue: false,
            statusCanceling: false,
            statusInactive: false,
            websitesPosts: null
        };
    }

    loadData() {
        fetch(`${FETCH_PATH}admin/traffic`, {
            headers: {'Content-Type': 'application/json', 'apiKey': 'AIzaSyBMgXC9PTgSRLzuwYL3K8U5MqxzTwX4w1I'}
        })
            .then(response => response.json())
            .then(data => {
                let dataError = false;
                for (const item of data.data) {

                    if (item.analytics.this_month.fs_sessions < 0) {
                        if (!dataError) {
                            dataError = true;
                            toast.error('Problem with analytics (fs_sessions). One ore more values have minus value', {
                                position: "bottom-center",
                                autoClose: false,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            });
                        }
                        item.analytics.this_month.fs_sessions = 0;
                    }
                }

                this.setState({websitesPosts: data.data});
            })
            .catch(err => console.error(this.props.url, err.toString()));
    }

    componentDidMount() {
        document.title = "ForeShop | Dashboard";
        this.loadData()
    }

    handleCompanyActive = (e) => {
        this.setState({statusCompanyActive: e.target.checked});
    }
    handleClientActive = (e) => {
        this.setState({statusClientActive: e.target.checked});
    }
    handleCompanyInactive = (e) => {
        this.setState({statusCompanyInactive: e.target.checked});
    }
    handleOnPause = (e) => {
        this.setState({statusOnPause: e.target.checked});
    }
    handlePaymentIssue = (e) => {
        this.setState({statusPaymentIssue: e.target.checked});
    }
    handleCanceling = (e) => {
        this.setState({statusCanceling: e.target.checked});
    }
    handleInactive = (e) => {
        this.setState({statusInactive: e.target.checked});
    }
    handleNoTop50Post = (e) => {
        this.setState({noTop50Post: e.target.checked});
    }
    handleWithTop50Post = (e) => {
        this.setState({withTop50Post: e.target.checked});
    }
    handleBursa = (e) => {
        this.setState({statusBursa: e.target.checked});
    }
    handleMin3days = (e) => {
        this.setState({min3days: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinThisMonth = (e) => {
        this.setState({minThisMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinLastMonth = (e) => {
        this.setState({minLastMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMax3days = (e) => {
        this.setState({max3days: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxThisMonth = (e) => {
        this.setState({maxThisMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxLastMonth = (e) => {
        this.setState({maxLastMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    reduceData = (arr, dimension, period) => {
        return arr.filter(e => e.analytics[`${period}`] && e.analytics[`${period}`][`${dimension}`])
            .reduce((a, e) => a += parseInt(e.analytics[`${period}`][`${dimension}`]), 0);
    }
    calcAverage = (arr, dimension, period) => {
        return (this.reduceData(arr, dimension, period) / arr.length).toFixed(2);
    }

    renderFilterForm() {
        return (
            <Form inline>
                <Form.Group className="mb-2">
                    <Form.Label className="mr-3">Website Status:</Form.Label>
                    <Form.Check type="checkbox" label="Bursa" onClick={this.handleBursa} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Company Active" onClick={this.handleCompanyActive}
                                className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Client Active" onClick={this.handleClientActive}
                                className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Company Inactive" onClick={this.handleCompanyInactive}
                                className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Inactive" onClick={this.handleInactive} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="On Pause" onClick={this.handleOnPause} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Payment Issue" onClick={this.handlePaymentIssue}
                                className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Canceling" onClick={this.handleCanceling}
                                className="mr-3 small"/>
                    <Form.Check type="checkbox" label="No Top" onClick={this.handleNoTop50Post} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="With Top" onClick={this.handleWithTop50Post}
                                className="mr-3 small"/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="mr-3">Minimum:</Form.Label>
                    <Form.Label className="mr-3">3 Days</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="3 Days" className="mr-2"
                                  onChange={this.handleMin3days} value={this.state.min3days}/>
                    <Form.Label className="mr-3">This Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="This Month" className="mr-2"
                                  onChange={this.handleMinThisMonth} value={this.state.minThisMonth}/>
                    <Form.Label className="mr-3">Last Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Last Month" className="mr-2"
                                  onChange={this.handleMinLastMonth} value={this.state.minLastMonth}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="mr-3">Maximum:</Form.Label>
                    <Form.Label className="mr-3">3 Days</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="3 Days" className="mr-2"
                                  onChange={this.handleMax3days} value={this.state.max3days}/>
                    <Form.Label className="mr-3">This Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="This Month" className="mr-2"
                                  onChange={this.handleMaxThisMonth} value={this.state.maxThisMonth}/>
                    <Form.Label className="mr-3">Last Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Last Month" className="mr-2"
                                  onChange={this.handleMaxLastMonth} value={this.state.maxLastMonth}/>
                </Form.Group>
            </Form>
        )
    }

    filterWebsitesPosts = () => {
        return this.state.websitesPosts
            .filter(e => {
                if (this.state.statusCompanyActive || this.state.statusClientActive || this.state.statusCompanyInactive
                    || this.state.statusOnPause || this.state.statusPaymentIssue || this.state.statusCanceling || this.state.statusInactive || this.state.statusBursa) {
                    switch (e.status) {
                        case "COMPANY_ACTIVE":
                            return this.state.statusCompanyActive;
                        case "CLIENT_ACTIVE":
                            return this.state.statusClientActive;
                        case "COMPANY_INACTIVE":
                            return this.state.statusCompanyInactive;
                        case "ON_PAUSE":
                            return this.state.statusOnPause;
                        case "PAYMENT_ISSUE":
                            return this.state.statusPaymentIssue;
                        case "CANCELING":
                            return this.state.statusCanceling;
                        case "BURSA":
                            return this.state.statusBursa;
                        default:
                            return this.state.statusInactive;
                    }
                } else {
                    return true;
                }
            })
            .filter(e => {
                let three_days = e.analytics.three_days.fs_sessions ? e.analytics.three_days.fs_sessions : 0;
                let this_month = e.analytics.this_month.fs_sessions ? e.analytics.this_month.fs_sessions : 0;
                let last_month = e.analytics.last_month.fs_sessions ? e.analytics.last_month.fs_sessions : 0;
                return this.state.min3days <= three_days
                    && three_days <= this.state.max3days
                    && this.state.minThisMonth <= this_month
                    && this_month <= this.state.maxThisMonth
                    && this.state.minLastMonth <= last_month
                    && last_month <= this.state.maxLastMonth
            })
    }

    renderTable() {
        let result = this.filterWebsitesPosts();
        let totalYesterdayFSSessions = this.reduceData(result, 'fs_sessions', 'yesterday');
        let total3DaysFSSessions = this.reduceData(result, 'fs_sessions', 'three_days');
        let totalThisMonthFSSessions = this.reduceData(result, 'fs_sessions', 'this_month');
        let totalLastMonthFSSessions = this.reduceData(result, 'fs_sessions', 'last_month');
        let total3DaysSessions = this.reduceData(result, 'sessions', 'three_days');
        let totalThisMonthSessions = this.reduceData(result, 'sessions', 'this_month');
        let totalLastMonthSessions = this.reduceData(result, 'sessions', 'last_month');
        let average3DaysBounceRate = this.calcAverage(result, 'bounceRate', 'three_days');
        let averageThisMonthBounceRate = this.calcAverage(result, 'bounceRate', 'this_month');
        let averageLastMonthBounceRate = this.calcAverage(result, 'bounceRate', 'last_month');
        let total3DaysPageViews = this.reduceData(result, 'pageviews', 'three_days');
        let totalThisMonthPageViews = this.reduceData(result, 'pageviews', 'this_month');
        let totalLastMonthPageViews = this.reduceData(result, 'pageviews', 'last_month');

        return (
            <div>
                <Table responsive striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2}>Domain</th>
                        <th rowSpan={2}>Status</th>
                        <th colSpan={4}>ForeShop Sessions</th>
                        <th colSpan={3}>Sessions</th>
                        <th colSpan={3}>Bounce Rate</th>
                        <th colSpan={3}>Page Views</th>
                        <th rowSpan={2}>Analytics</th>
                    </tr>
                    <tr>
                        <th><small>yesterday {totalYesterdayFSSessions}</small></th>
                        <th><small>3 days {total3DaysFSSessions}</small></th>
                        <th><small>this month {totalThisMonthFSSessions}</small></th>
                        <th><small>last month {totalLastMonthFSSessions}</small></th>
                        <th><small>3 days {total3DaysSessions}</small></th>
                        <th><small>this month {totalThisMonthSessions}</small></th>
                        <th><small>last month {totalLastMonthSessions}</small></th>
                        <th><small>3 days {average3DaysBounceRate}</small></th>
                        <th><small>this month {averageThisMonthBounceRate}</small></th>
                        <th><small>last month {averageLastMonthBounceRate}</small></th>
                        <th><small>3 days {total3DaysPageViews}</small></th>
                        <th><small>this month {totalThisMonthPageViews}</small></th>
                        <th><small>last month {totalLastMonthPageViews}</small></th>
                    </tr>
                    </thead>
                    <tbody>
                    {result.map((e, i) => {
                        let statusClass = "text-danger";
                        if (e.status === "CLIENT_ACTIVE" || e.status === "COMPANY_ACTIVE" || e.status === "BURSA") {
                            statusClass = 'text-success';
                        } else if (e.status === 'INACTIVE') {
                            statusClass = 'text-secondary';
                        }
                        return (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td><a href={`/website/${e.domain}`} target="_blank"
                                       rel="noopener noreferrer">{e.domain}</a></td>
                                <td className={statusClass}><small>{e.status}</small></td>
                                <td>{e.analytics.yesterday && e.analytics.yesterday.fs_sessions}</td>
                                <td>{e.analytics.three_days && e.analytics.three_days.fs_sessions}</td>
                                <td>{e.analytics.this_month && e.analytics.this_month.fs_sessions}</td>
                                <td>{e.analytics.last_month && e.analytics.last_month.fs_sessions}</td>
                                <td>{e.analytics.three_days && e.analytics.three_days.sessions}</td>
                                <td>{e.analytics.this_month && e.analytics.this_month.sessions}</td>
                                <td>{e.analytics.last_month && e.analytics.last_month.sessions}</td>
                                <td>{e.analytics.three_days && e.analytics.three_days.bounceRate}</td>
                                <td>{e.analytics.this_month && e.analytics.this_month.bounceRate}</td>
                                <td>{e.analytics.last_month && e.analytics.last_month.bounceRate}</td>
                                <td>{e.analytics.three_days && e.analytics.three_days.pageviews}</td>
                                <td>{e.analytics.this_month && e.analytics.this_month.pageviews}</td>
                                <td>{e.analytics.last_month && e.analytics.last_month.pageviews}</td>
                                <td><small className="text-nowrap">{e.analytics.tracking_id}</small></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <ToastContainer
                    position="bottom-center"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                />
            </div>
        )
    }

    render() {
        return (
            <Col>
                {this.renderFilterForm()}
                {this.state.websitesPosts && this.renderTable()}
            </Col>
        );
    }
}

export default Traffic;
