import React from "react";
import {Col, Form, Table} from "react-bootstrap";
import { FETCH_PATH } from "../../constants";

class Posts extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            minLastWeek: 0,
            minThisMonth: 0,
            minLastMonth: 0,
            maxLastWeek: 999999999,
            maxThisMonth: 999999999,
            maxLastMonth: 999999999,
            noTop50Post: false,
            withTop50Post: false,
            statusCompanyActive: false,
            statusClientActive: false,
            statusCompanyInactive: false,
            statusOnPause: false,
            statusPaymentIssue: false,
            statusCanceling: false,
            statusInactive: false,
            websitesPosts: null,
            posts: 0
        };
    }
    loadData() {
        fetch(`${FETCH_PATH}admin/dashboard`)
            .then(response => response.json())
            .then(data => {
                this.setState({websitesPosts: data.websites, posts: data.posts});
            })
            .catch(err => console.error(this.props.url, err.toString()));
        fetch('/src/IAB-Categories.json')
            .then(response => response.json())
            .then(data => {
                this.setState({cat: data.data});
            })
            .catch(err => console.error(this.props.url, err.toString()));
    }
    componentDidMount() {
        document.title = "ForeShop | Dashboard";
        this.loadData()
    }
    handleCompanyActive = (e) => {
        this.setState({statusCompanyActive: e.target.checked});
    }
    handleClientActive = (e) => {
        this.setState({statusClientActive: e.target.checked});
    }
    handleCompanyInactive = (e) => {
        this.setState({statusCompanyInactive: e.target.checked});
    }
    handleOnPause = (e) => {
        this.setState({statusOnPause: e.target.checked});
    }
    handlePaymentIssue = (e) => {
        this.setState({statusPaymentIssue: e.target.checked});
    }
    handleCanceling = (e) => {
        this.setState({statusCanceling: e.target.checked});
    }
    handleInactive = (e) => {
        this.setState({statusInactive: e.target.checked});
    }
    handleNoTop50Post = (e) => {
        this.setState({noTop50Post: e.target.checked});
    }
    handleWithTop50Post = (e) => {
        this.setState({withTop50Post: e.target.checked});
    }
    handleMinLastWeek = (e) => {
        this.setState({minLastWeek: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinThisMonth = (e) => {
        this.setState({minThisMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinLastMonth = (e) => {
        this.setState({minLastMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxLastWeek = (e) => {
        this.setState({maxLastWeek: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxThisMonth = (e) => {
        this.setState({maxThisMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxLastMonth = (e) => {
        this.setState({maxLastMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    reduceLastWeekPosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.week_posts), 0);
    }
    reduceThisMonthPosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.this_month), 0);
    }
    reduceLastMonthPosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.month_posts), 0);
    }
    reduceAllTimePosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.posts), 0);
    }
    renderFilterForm(){
        return(
            <div>
                <Form className="mb-2" inline>
                    <Form.Label className="mr-3">Website Status:</Form.Label>
                    <Form.Check type="checkbox" label="Company Active" onClick={this.handleCompanyActive} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Client Active" onClick={this.handleClientActive} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Company Inactive" onClick={this.handleCompanyInactive} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Inactive" onClick={this.handleInactive} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="On Pause" onClick={this.handleOnPause} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Payment Issue" onClick={this.handlePaymentIssue} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="Canceling" onClick={this.handleCanceling} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="No Top" onClick={this.handleNoTop50Post} className="mr-3 small"/>
                    <Form.Check type="checkbox" label="With Top" onClick={this.handleWithTop50Post} className="mr-3 small"/>
                </Form>
                <Form className="mb-2" inline>
                    <Form.Label className="mr-3">Minimum:</Form.Label>
                    <Form.Label className="mr-3">Last Week</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="3 Days" className="mr-2" onChange={this.handleMinLastWeek} value={this.state.minLastWeek} />
                    <Form.Label className="mr-3">This Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="This Month" className="mr-2"  onChange={this.handleMinThisMonth} value={this.state.minThisMonth}/>
                    <Form.Label className="mr-3">Last Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Last Month" className="mr-2"  onChange={this.handleMinLastMonth} value={this.state.minLastMonth}/>
                </Form>
                <Form className="mb-2" inline>
                    <Form.Label className="mr-3">Maximum:</Form.Label>
                    <Form.Label className="mr-3">Last Week</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="3 Days" className="mr-2" onChange={this.handleMaxLastWeek} value={this.state.maxLastWeek}/>
                    <Form.Label className="mr-3">This Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="This Month" className="mr-2"  onChange={this.handleMaxThisMonth} value={this.state.maxThisMonth}/>
                    <Form.Label className="mr-3">Last Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Last Month" className="mr-2"  onChange={this.handleMaxLastMonth} value={this.state.maxLastMonth}/>
                </Form>
            </div>
        )
    }
    filterWebsitesPosts = () => {
        return this.state.websitesPosts
            .filter(e => {
                if(this.state.noTop50Post) {
                    return !!!e.top_post
                } else {
                    return true;
                }
            })
            .filter(e => {
                if(this.state.withTop50Post) {
                    return !!e.top_post
                } else {
                    return true;
                }
            })
            .filter(e => {
                if(this.state.statusCompanyActive || this.state.statusClientActive || this.state.statusCompanyInactive
                    || this.state.statusOnPause || this.state.statusPaymentIssue || this.state.statusCanceling || this.state.statusInactive) {
                    switch (e.status){
                        case "COMPANY_ACTIVE": return this.state.statusCompanyActive;
                        case "CUSTOMER_ACTIVE": return this.state.statusClientActive;
                        case "COMPANY_INACTIVE": return this.state.statusCompanyInactive;
                        case "ON_PAUSE": return this.state.statusOnPause;
                        case "PAYMENT_ISSUE": return this.state.statusPaymentIssue;
                        case "CANCELING": return this.state.statusCanceling;
                        default: return this.state.statusInactive;
                    }
                } else {
                    return true;
                }
            })
            .filter(e => {
                return e.week_posts >= this.state.minLastWeek
                    && e.this_month >= this.state.minThisMonth
                    && e.month_posts >= this.state.minLastMonth
                    && e.week_posts <= this.state.maxLastWeek
                    && e.this_month <= this.state.maxThisMonth
                    && e.month_posts <= this.state.maxLastMonth
            })
    }
    renderTable(){
        console.log(this.state.cat);
        let result = this.filterWebsitesPosts();
        let totalLastWeekPosts = this.reduceLastWeekPosts(result);
        let totalThisMonthPosts = this.reduceThisMonthPosts(result);
        let totalLastMonthPosts = this.reduceLastMonthPosts(result);
        let totalAllTimePosts = this.reduceAllTimePosts(result);
        return (
            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>Category</th>
                    <th>Week <small>{totalLastWeekPosts}</small></th>
                    <th>This Month <small>{totalThisMonthPosts}</small></th>
                    <th>Last Month <small>{totalLastMonthPosts}</small></th>
                    <th>All time <small>{totalAllTimePosts}</small></th>
                    <th>Feed Checked</th>
                    <th>Top Post</th>
                    <th>Cache</th>
                </tr>
                </thead>
                <tbody>
                {result.map((e, i) => {
                    let statusClass = "text-danger";
                    if(e.status === "CUSTOMER_ACTIVE" || e.status === "COMPANY_ACTIVE"){
                        statusClass = 'text-success';
                    } else if (e.status === 'INACTIVE') {
                        statusClass = 'text-secondary';
                    }
                    return(
                        <tr key={i} >
                            <td>{i+1}</td>
                            <td><a href={`/website/${e.domain}`} target="_blank" rel="noopener noreferrer">{e.domain}</a></td>
                            <td className={statusClass}><small>{e.status}</small></td>
                            <td><small>{this.state.cat && this.state.cat[e.category]} <span className="small">{e.category}</span></small></td>
                            <td>{e.week_posts}</td>
                            <td>{e.this_month}</td>
                            <td>{e.month_posts}</td>
                            <td>{e.posts}</td>
                            <td>{dateToStr(e.feed_parsed)}</td>
                            <td>{renderLinkToTopPost(e.domain, e.top_post)}</td>
                            <td><a href={`https://${e.domain}/wp-json/hummingbird/v1/clear_cache/page_cache`} target="_blank" rel="noopener noreferrer">Clear</a></td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )
    }
    render() {
        return(
            <Col>
                {this.renderFilterForm()}
                {this.state.websitesPosts && this.renderTable()}
            </Col>
        );
    }
}
function dateToStr(date){
    let r = date !== 0 ? new Date(date*1000).toLocaleDateString() : '-';
    return r !== 'Invalid Date' ? r : '-';
}
function renderLinkToTopPost(domain, link) {
    if(link){
        return (
            <span>
                <a href={`https://foreshop.net/post/${link}`} target='_blank' rel="noopener noreferrer" className='mr-2'>Inside</a>
                <a href={`https://${domain}/${link}`} target='_blank' rel="noopener noreferrer">Out</a>
            </span>
            )
    }
}
export default Posts;
