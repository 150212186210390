import {FETCH_PATH} from "../constants";
export default function ClickHandler(e, query) {
    let params = query.split("trck=")
    if(params.length > 1){
        let tracker = params[1].split("&")[0];
        fetch(`http://xml.foremedia.net/conversion?id=2775798&c=${tracker}&count=1&value=0`).catch(err => console.error(err.toString()));
        console.log('done tracking')
    }
    if (window.location.href.indexOf(e.slug) === -1){
        fetch(FETCH_PATH + 'inc/' + e.slug).catch(err => console.error(err.toString()));
    }
    window.open(e.url?? `https://${e.website}/${e.slug}/${query}`, "_blank");
}
