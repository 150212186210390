import React from "react";
import {Container, Row} from "react-bootstrap";
import { AdminMenu, Header, Footer, Posts, Status, PushStats, Traffic, Files } from "../";
import EmailStats from "./EmailStats";
import SocialMedia from "./SocialMedia";
import Plan from "./Plan";

export default function Admin(e){
    const renderElement = (e) => {
        switch(e){
            case 'Status' :
                return <Status/>;
            case 'Push' :
                return <PushStats/>;
            case 'Email' :
                return <EmailStats/>;
            case 'Traffic' :
                return <Traffic/>;
            case 'Files' :
                return <Files/>;
            case 'Social Media' :
                return <SocialMedia/>;
            case 'Plan':
                return <Plan/>
            default:
                return <Posts/>
        }
    }
        return(
            <div>
                <Header/>
                <Container fluid>
                    <AdminMenu title={e}/>
                    <Row className='mb-4'>
                        {renderElement(e)}
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
}
