import React from "react";
import { Link } from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import { Header, Footer } from "./";

export default function NotFound(){
    return(
        <div>
            <Header/>
            <Container fluid id="container" className={"h-100"}>
                <Row>
                    <Col className="my-4 text-center">
                        <h1 className="display-1">404</h1>
                        <h3>We can't find this page you're looking for.</h3>
                        <Link to="/">Go to Home </Link>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    );
}
