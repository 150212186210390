import React from "react";
import {FETCH_PATH} from "../../constants";
import {Col, Form, Image, ProgressBar, Table} from "react-bootstrap";
import $ from 'jquery';
import 'floatthead'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ReactComponent as Star} from '../../assets/svg/star.svg'
import {ReactComponent as Star_gold} from '../../assets/svg/star-golden.svg'

class Plan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            available: false,
            min3days: 0,
            minLastWeek: 0,
            minThisMonth: 0,
            minLastMonth: 0,
            max3days: 999999999,
            maxLastWeek: 999999999,
            maxThisMonth: 999999999,
            maxLastMonth: 999999999,
            minRetainer: 0,
            maxRetainer: 999999999,
            statusBursa: false,
            noTop50Post: false,
            withTop50Post: false,
            statusCompanyActive: false,
            statusClientActive: false,
            statusCompanyInactive: false,
            statusOnPause: false,
            statusPaymentIssue: false,
            statusCanceling: false,
            statusInactive: false,
            statusBlacklist: false,
            websitesPosts: null,
            posts: 0,
            sortActive: false,
            sortValue: '',
            direction: 'h-l',
            defaultClicks: 5000,
            defaultBudget: 60,
            minPlan: 0,
            maxPlan: 999999999
        };
    }

    loadData() {
        fetch(`${FETCH_PATH}admin/dashboard`)
            .then(response => response.json())
            .then(data => {
                const {cat} = this.state;
                let webData = data;
                let dataError = false;

                webData['websites'].forEach(
                    item => {
                        if (item.analytics.this_month.fs_sessions < 0) {
                            if (!dataError) {
                                dataError = true;
                                toast.error(`Problem with analytics ${item.domain}`, {
                                    position: "bottom-center",
                                    autoClose: false,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            }
                            item.analytics.this_month.fs_sessions = 0;
                        }
                        if(item.retainer > 1000 || !item.retainer){
                            item.retainer = 0;
                        }
                        try {
                            item.category = cat[item.category];
                        } catch (e) {
                            // console.log(e)
                        }

                    }
                )
                this.website_status_counter(webData.websites);
                console.log(webData)
                this.setState({websitesPosts: webData.websites, posts: webData.posts});
            })
            .catch(err => console.error(this.props.url, err.toString()))
    }

    rate_item(event, domain, rated) {
        const initialClass = event.target.className;
        event.target.setAttribute('class',initialClass.baseVal+' active')
        const rateVal = !!!rated;
        fetch(`${FETCH_PATH}admin/rate`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({domain: domain, rated: rateVal})
        })
            .then(res => res.json())
            .then(res => {
                for (let i = 0; i < this.state.websitesPosts.length; i++) {
                    if (res[0].domain === this.state.websitesPosts[i].domain) {
                        this.setState(state => {
                            state.websitesPosts[i].rated = res[0].rated;
                        })
                        this.forceUpdate();
                        return;
                    }
                }
            }).finally(() => {
        })
    }

    website_status_counter = (array) => {
        const statusesCount = {CA: 0, CLA: 0, CI: 0, I: 0, OP: 0, PI: 0, C: 0, B: 0, BL: 0}
        const statuses = {
            CA: 'COMPANY_ACTIVE',
            CLA: 'CUSTOMER_ACTIVE',
            CLA_2: 'CLIENT_ACTIVE', // The Same as CUSTOMER_ACTIVE!
            CI: 'COMPANY_INACTIVE',
            I: "INACTIVE",
            OP: 'ON_PAUSE',
            PI: 'PAYMENT_ISSUE',
            C: 'CANCELING',
            B: 'BURSA',
            BL: "BLACK_LIST"
        }
        for (const item of array) {
            switch (item.status) {
                case statuses.B:
                    statusesCount.B += 1;
                    break;
                case statuses.C:
                    statusesCount.C += 1;
                    break;
                case statuses.CA:
                    statusesCount.CA += 1;
                    break;
                case statuses.CI:
                    statusesCount.CI += 1;
                    break;
                case statuses.CLA:
                    statusesCount.CLA += 1;
                    break;
                case statuses.CLA_2:
                    statusesCount.CLA += 1;
                    break;
                case statuses.I:
                    statusesCount.I += 1;
                    break;
                case statuses.OP:
                    statusesCount.OP += 1;
                    break;
                case statuses.PI:
                    statusesCount.PI += 1;
                    break;
                case statuses.BL:
                    statusesCount.BL += 1;
                    break

            }
        }
        this.setState(state => {
            state.B = statusesCount.B
            state.C = statusesCount.C
            state.CA = statusesCount.CA
            state.CI = statusesCount.CI
            state.CLA = statusesCount.CLA
            state.I = statusesCount.I
            state.OP = statusesCount.OP
            state.PI = statusesCount.PI
            state.BL = statusesCount.BL
        })
    }

    get_categories() {
        fetch('/src/IAB-Categories.json')
            .then(response => response.json())
            .then(data => {
                this.setState({cat: data.data}, () => this.loadData());
                console.log(this.state.cat)
            })
            .catch(err => {
                this.loadData()
                console.error(this.props.url, err.toString())
            });
    }

    watch_table_header() {
        let target = document.querySelector("#root > div > div.container-fluid > div.mb-4.row > div > div:nth-child(2) > div.table-responsive > div");
        let translate = target.style.transform;
        window.addEventListener('scroll', () =>
            this.setState({tableTranslate: translate})
        )
    }

    componentDidMount() {
        document.title = "ForeShop | Dashboard";
        this.get_categories()

        const attachHeader = setInterval(() => {
            try {
                let $table = $('table.demo');
                $table.floatThead({
                    position: 'fixed',
                    responsiveContainer: function ($table) {
                        return $table.closest('.table-responsive');
                    }
                });
                clearInterval(attachHeader);
                // this.watch_table_header();


            } catch (e) {
                console.log(e.toString())
            }
        }, 2000);

    }

    handleBursa = (e) => {
        this.setState({statusBursa: e.target.checked});
    }
    handleCompanyActive = (e) => {
        this.setState({statusCompanyActive: e.target.checked});
    }
    handleClientActive = (e) => {
        this.setState({statusClientActive: e.target.checked});
    }
    handleCompanyInactive = (e) => {
        this.setState({statusCompanyInactive: e.target.checked});
    }

    handleOnPause = (e) => {
        this.setState({statusOnPause: e.target.checked});
    }
    handlePaymentIssue = (e) => {
        this.setState({statusPaymentIssue: e.target.checked});
    }
    handleCanceling = (e) => {
        this.setState({statusCanceling: e.target.checked});
    }
    handleBlacklist = (e) => {
        this.setState({statusBlacklist: e.target.checked});
    }
    handleInactive = (e) => {
        this.setState({statusInactive: e.target.checked});
    }
    handleMin3days = (e) => {
        this.setState({min3days: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMax3days = (e) => {
        this.setState({max3days: parseInt(e.target.value ? e.target.value : 0, 10)});
    }

    handleNoTop50Post = (e) => {
        this.setState({noTop50Post: e.target.checked});
    }
    handleWithTop50Post = (e) => {
        this.setState({withTop50Post: e.target.checked});
    }
    handleMinLastWeek = (e) => {
        this.setState({minLastWeek: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinThisMonth = (e) => {
        this.setState({minThisMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinPlan = (e) => {
        this.setState({minPlan: parseInt(e.target.value ? e.target.value > 0 ? e.target.value : 0 : 0)})
    }
    handleMinRetainer = (e) => {
        this.setState({minRetainer: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMinLastMonth = (e) => {
        this.setState({minLastMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxLastWeek = (e) => {
        this.setState({maxLastWeek: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxThisMonth = (e) => {
        this.setState({maxThisMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    handleMaxPlan = (e) => {
        this.setState({maxPlan: parseInt(e.target.value ? e.target.value > 1 ? e.target.value : 1 : 9999)})
    }
    handleMaxRetainer = (e) => {
        this.setState({maxRetainer: parseInt(e.target.value ? e.target.value : 0, 10)});
    }

    handleMaxLastMonth = (e) => {
        this.setState({maxLastMonth: parseInt(e.target.value ? e.target.value : 0, 10)});
    }
    reduceLastWeekPosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.week_posts), 0);
    }
    reduceThisMonthPosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.this_month), 0);
    }
    reduceLastMonthPosts = (result) => {
        return result.reduce((a, e) => a += parseInt(e.month_posts), 0);
    }

    renderFilterForm() {
        return (
            <div className={'forms'}>
                <p>Website Status</p>
                <Form className="mb-2" inline>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-1' onClick={this.handleBursa}/>
                        <label htmlFor="check-1">{`(${this.state.B ?? 0}) Bursa`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-2'
                               onClick={this.handleCompanyActive}/>
                        <label htmlFor="check-2">{`(${this.state.CA ?? 0}) Company Active`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-3'
                               onClick={this.handleClientActive}/>
                        <label htmlFor="check-3">{`(${this.state.CLA ?? 0}) Client Active`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-4'
                               onClick={this.handleCompanyInactive}/>
                        <label htmlFor="check-4">{`(${this.state.CI ?? 0}) Company Inactive`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-5' onClick={this.handleInactive}/>
                        <label htmlFor="check-5">{`(${this.state.I ?? 0}) Inactive`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-6' onClick={this.handleOnPause}/>
                        <label htmlFor="check-6">{`(${this.state.OP ?? 0}) On Pause`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-7'
                               onClick={this.handlePaymentIssue}/>
                        <label htmlFor="check-7">{`(${this.state.PI ?? 0}) Payment issue`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-8' onClick={this.handleCanceling}/>
                        <label htmlFor="check-8">{`(${this.state.C ?? 0}) Cancelling`}</label>
                    </div>
                    <div className="checkbox">
                        <input className="custom-checkbox" type="checkbox" id='check-9' onClick={this.handleBlacklist}/>
                        <label htmlFor="check-9">{`(${this.state.BL ?? 0}) Black list`}</label>
                    </div>
                </Form>
                <p>Filters</p>
                <Form className="mb-2" inline>
                    <Form.Label className="mr-3">Minimum:</Form.Label>
                    <Form.Label className="mr-3">3 Days</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="3 Days" className="mr-2"
                                  onChange={this.handleMin3days} value={this.state.min3days}/>
                    <Form.Label className="mr-3">This Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="This Month" className="mr-2"
                                  onChange={this.handleMinThisMonth} value={this.state.minThisMonth}/>
                    <Form.Label className="mr-3">Retainer</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Retainer" className="mr-2"
                                  onChange={this.handleMinRetainer} value={this.state.minRetainer}/>
                    <Form.Label className="mr-3">Plan</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Plan" className="mr-2"
                                  onChange={this.handleMinPlan} value={this.state.minPlan}/>
                </Form>
                <Form className="mb-2" inline>
                    <Form.Label className="mr-3">Maximum:</Form.Label>
                    <Form.Label className="mr-3">3 Days</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="3 Days" className="mr-2"
                                  onChange={this.handleMax3days} value={this.state.max3days}/>
                    <Form.Label className="mr-3">This Month</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="This Month" className="mr-2"
                                  onChange={this.handleMaxThisMonth} value={this.state.maxThisMonth}/>
                    <Form.Label className="mr-3">Retainer</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Retainer" className="mr-2"
                                  onChange={this.handleMaxRetainer} value={this.state.maxRetainer}/>
                    <Form.Label className="mr-3">Plan</Form.Label>
                    <Form.Control size="sm" type="number" placeholder="Plan" className="mr-2"
                                  onChange={this.handleMaxPlan} value={this.state.maxPlan}/>
                </Form>
            </div>
        )
    }

    filterWebsitesPosts = () => {
        try {
            return this.state.websitesPosts
                .filter(e => {
                    if (this.state.noTop50Post) {
                        return !!!e.top_post
                    } else {
                        return true;
                    }
                })
                .filter(e => {
                    if (this.state.withTop50Post) {
                        return !!e.top_post
                    } else {
                        return true;
                    }
                })
                .filter(e => {
                    if (this.state.statusCompanyActive || this.state.statusClientActive || this.state.statusCompanyInactive
                        || this.state.statusOnPause || this.state.statusBlacklist || this.state.statusPaymentIssue || this.state.statusCanceling || this.state.statusInactive || this.state.statusBursa) {
                        switch (e.status) {
                            case "COMPANY_ACTIVE":
                                return this.state.statusCompanyActive;
                            case "CUSTOMER_ACTIVE":
                                return this.state.statusClientActive;
                            case "CLIENT_ACTIVE":
                                return this.state.statusClientActive;
                            case "COMPANY_INACTIVE":
                                return this.state.statusCompanyInactive;
                            case "ON_PAUSE":
                                return this.state.statusOnPause;
                            case "PAYMENT_ISSUE":
                                return this.state.statusPaymentIssue;
                            case "CANCELING":
                                return this.state.statusCanceling;
                            case "BURSA":
                                return this.state.statusBursa;
                            case "BLACK_LIST":
                                return this.state.statusBlacklist;
                            default:
                                return this.state.statusInactive;
                        }
                    } else {
                        return true;
                    }
                })
                .filter(e => {
                    let three_days = e.analytics.three_days.fs_sessions ? e.analytics.three_days.fs_sessions : 0;
                    let this_month = e.analytics.this_month.fs_sessions ? e.analytics.this_month.fs_sessions : 0;
                    let last_month = e.analytics.last_month.fs_sessions ? e.analytics.last_month.fs_sessions : 0;
                    let retainer = e.retainer ? e.retainer : 0;
                    return this.state.min3days <= three_days
                        && this.state.minPlan <= this.calculate_bar(e.analytics.this_month.fs_sessions, this.calculate_clicks(e['retainer']))
                        && this.state.minRetainer <= retainer
                        && three_days <= this.state.max3days
                        && this.state.minThisMonth <= this_month
                        && this_month <= this.state.maxThisMonth
                        && this.state.minLastMonth <= last_month
                        && last_month <= this.state.maxLastMonth
                        && this.calculate_bar(e.analytics.this_month.fs_sessions, this.calculate_clicks(e['retainer'])) <= this.state.maxPlan
                        && retainer <= this.state.maxRetainer;
                })
        } catch (e) {
            console.log(e)
        }
    }

    reduceData = (arr, dimension, period) => {
        return arr.filter(e => e.analytics[`${period}`] && e.analytics[`${period}`][`${dimension}`])
            .reduce((a, e) => a += parseInt(e.analytics[`${period}`][`${dimension}`]), 0);
    }

    sort(a, b) {
        const {sortValue, direction} = this.state;
        const hl = [-1, 1], lh = [1, -1];

        if (sortValue === 'category') {
            if (a.category > b.category) {
                return direction === 'h-l' ? hl[0] : lh[0]
            } else if (a.category < b.category) {
                return direction === 'h-l' ? hl[1] : lh[1]
            } else {
                return 0;
            }
        } else if (sortValue === 'retainer') {
            if (parseInt(a[sortValue]) > parseInt(b[sortValue])) {
                return direction === 'h-l' ? hl[0] : lh[0]
            } else if (parseInt(a[sortValue]) < parseInt(b[sortValue])) {
                return direction === 'h-l' ? hl[1] : lh[1]
            } else {
                return 0
            }
        } else if (sortValue === 'status_changed') {

            if (a[sortValue][a[sortValue].length - 1].date > b[sortValue][b[sortValue].length - 1].date) {
                return direction === 'h-l' ? hl[0] : lh[0]
            } else if (a[sortValue][a[sortValue].length - 1].date < b[sortValue][b[sortValue].length - 1].date) {
                return direction === 'h-l' ? hl[1] : lh[1]
            } else {
                return 0
            }
        } else if (sortValue === 'plan') {
            const aClicks = this.calculate_clicks(a['retainer']);
            const bClicks = this.calculate_clicks(b['retainer']);
            const aLytics = a.analytics.this_month.fs_sessions;
            const bLytics = b.analytics.this_month.fs_sessions;
            const aCalc = this.calculate_bar(aLytics, aClicks);
            const bCalc = this.calculate_bar(bLytics, bClicks);
            console.log(aCalc, bCalc)
            const result = aCalc > bCalc;
            if (result) {
                return direction === 'h-l' ? hl[0] : lh[0]
            } else if (!result) {
                return direction === 'h-l' ? hl[1] : lh[1]
            } else {
                return 0
            }
        } else {
            if (a['analytics'][sortValue]['fs_sessions'] > b['analytics'][sortValue]['fs_sessions']) {
                return direction === 'h-l' ? hl[0] : lh[0]
            } else if (a['analytics'][sortValue]['fs_sessions'] < b['analytics'][sortValue]['fs_sessions']) {
                return direction === 'h-l' ? hl[1] : lh[1]
            } else {
                return 0
            }
        }
    }

    set_sortValue(val) {
        const {sortValue, direction} = this.state;
        const check = direction === 'l-h';
        if (sortValue === val) {
            this.setState({sortValue: val, direction: check ? 'h-l' : 'l-h'}, () => this.forceUpdate())
        } else {
            this.setState({sortValue: val, sortActive: true, direction: 'h-l'}, () => this.forceUpdate())
        }
    }

    disable_sort = e => {
        e.preventDefault();
        this.setState({sortActive: false, sortValue: '', direction: 'h-l'})
    }

    calculate_clicks(_retainer) {
        const {defaultClicks} = this.state;
        const retainer = parseInt(_retainer)
        const shouldMultiply = retainer > 13;
        const multiplier = 0.025;
        if (retainer > 1000) {
            return 5000;
        }
        if (shouldMultiply && retainer > 13) {
            let val = 5000;
            for(let i = 0; i < (retainer-13); i++){
                val = (val*multiplier)+val;
            }
            return val.toFixed(0);
        } else {
            return defaultClicks;
        }
    }

    calculate_budget(_retainer) {
        const {defaultBudget} = this.state;
        const retainer = parseInt(_retainer)
        const shouldMultiply = retainer > 1;

        if (retainer === 1450) {
            return 0;
        }
        if (shouldMultiply && retainer < 10) {
            let calculated = defaultBudget;
            for (let i = 1; i < retainer; i++) {
                calculated *= 1.12;
            }
            return calculated.toFixed();
        } else if (shouldMultiply && retainer > 9) {
            return 234;
        } else {
            return defaultBudget;
        }
    }

    calculate_bar(fact, plan) {
        try {
            const isHundreed = ((parseInt(fact) / parseInt(plan)) * 100).toFixed();
            return isHundreed > 100 ? 100 : parseInt(isHundreed, 10);
        }catch(e){
            return 0
        }
    }


    renderTable() {
        const {sortActive, direction, sortValue} = this.state;
        let result = this.filterWebsitesPosts();
        let totalYesterdayFSSessions = this.reduceData(result, 'fs_sessions', 'yesterday');
        let total3DaysFSSessions = this.reduceData(result, 'fs_sessions', 'three_days');
        let totalThisMonthFSSessions = this.reduceData(result, 'fs_sessions', 'this_month');
        let totalLastMonthFSSessions = this.reduceData(result, 'fs_sessions', 'last_month');
        return (
            <div>
                <Table className={'demo'} responsive striped bordered hover size="sm">
                    <thead>
                    <tr className="text-center">
                        <th className="text-center man" rowSpan={2}>#</th>
                        {/*<th rowSpan={2} className="text-center man"><Star_gold/></th>*/}
                        <th className="text-center man" rowSpan={2}>Domain</th>
                        <th className="text-center man" rowSpan={2}>Status</th>
                        <th onContextMenu={this.disable_sort}
                            onClick={() => this.set_sortValue('retainer')} rowSpan={2}
                            className="text-center gan"><i
                            className={`arrow-up ${sortValue === 'retainer' ? direction : 'no'}`}/>Retainer
                        </th>
                        <th rowSpan={2} onContextMenu={this.disable_sort} onClick={() => this.set_sortValue('category')}
                            className="text-center gan">
                            <i className={`arrow-up ${sortValue === 'category' ? direction : 'no'}`}/> Category Name
                        </th>
                        <th className="text-center man" colSpan={3}>ForeShop Sessions</th>
                        <th className="text-center man" colSpan={2}>Limits</th>
                        {/*<th onContextMenu={this.disable_sort}*/}
                        {/*    onClick={() => this.set_sortValue('status_changed')} rowSpan={2}*/}
                        {/*    className="text-center gan"><i*/}
                        {/*    className={`arrow-up ${sortValue === 'status_changed' ? direction : 'no'}`}/>Status*/}
                        {/*    Changed*/}
                        {/*</th>*/}
                        <th className="text-center man" rowSpan={2}>
                            Analytics
                        </th>
                    </tr>
                    <tr>
                        <th onContextMenu={this.disable_sort} onClick={() => this.set_sortValue('yesterday')}
                            className="text-center gan "><small>1 day</small><br/><i
                            className={`arrow-up ${sortValue === 'yesterday' ? direction : 'no'}`}/>
                            <b>{totalYesterdayFSSessions}</b>
                        </th>
                        {/*<th onContextMenu={this.disable_sort} onClick={() => this.set_sortValue('three_days')}*/}
                        {/*    className="text-center gan"><small>3 days</small><br/><i*/}
                        {/*    className={`arrow-up ${sortValue === 'three_days' ? direction : 'no'}`}/>*/}
                        {/*    <b>{total3DaysFSSessions}</b>*/}
                        {/*</th>*/}
                        <th onContextMenu={this.disable_sort} onClick={() => this.set_sortValue('this_month')}
                            className="text-center gan"><small>30 days</small><br/><i
                            className={`arrow-up ${sortValue === 'this_month' ? direction : 'no'}`}/>
                            <b> {totalThisMonthFSSessions}</b>
                        </th>
                        <th onContextMenu={this.disable_sort} onClick={() => this.set_sortValue('last_month')}
                            className="text-center gan"><small>Last month</small><br/><i
                            className={`arrow-up ${sortValue === 'last_month' ? direction : 'no'}`}/>
                            <b> {totalLastMonthFSSessions}</b>
                        </th>
                        <th className="text-center man">Clicks</th>
                        {this.state.available && <th className="text-center man">Budget</th>}
                        <th onContextMenu={this.disable_sort} onClick={() => this.set_sortValue('plan')}
                            className="text-center gan">
                            <b>Plan</b>
                            <i
                                className={`arrow-up ${sortValue === 'plan' ? direction : 'no'}`}/>
                        </th>

                    </tr>
                    </thead>
                    <tbody className={'f-table'}>
                    {result.sort((a, b) => {
                        return sortActive ? this.sort(a, b) : ''
                    }).map((e, i) => {
                        const overDone = this.calculate_bar(e.analytics.this_month.fs_sessions, this.calculate_clicks(e['retainer'])) > 99
                        let statusClass = "text-danger";
                        if (e.status === "CUSTOMER_ACTIVE" || e.status === "CLIENT_ACTIVE" || e.status === "COMPANY_ACTIVE" || e.status === 'BURSA') {
                            statusClass = 'text-success';
                        } else if (e.status === 'INACTIVE') {
                            statusClass = 'text-secondary';
                        }
                        return (
                            <tr style={{backgroundColor: e.retainer < 1450 && overDone ? 'rgba(126, 207, 0, 0.6 )' : ''}}
                                key={i}>
                                <td className="text-center">{i + 1}</td>
                                {/*<td onClick={event => this.rate_item(event, e.domain, e.rated)}>{e.rated ?*/}
                                {/*    <Star_gold/> :*/}
                                {/*    <Star/>}</td>*/}
                                <td><a href={`https://${e.domain}`} target="_blank"
                                       rel="noopener noreferrer">{e.domain}</a></td>
                                <td className={statusClass}><small>{e.status}</small></td>
                                <td width={100}>{e['retainer'] > 1449 ? '---' : e['retainer']}</td>
                                <td>{`${e.category || ''}`}</td>
                                <td className="text-center">{e.analytics.yesterday && e.analytics.yesterday.fs_sessions}</td>
                                {/*<td className="text-center">{e.analytics.three_days && e.analytics.three_days.fs_sessions}</td>*/}
                                <td className="text-center">{e.analytics.this_month && e.analytics.this_month.fs_sessions}</td>
                                <td className="text-center">{e.analytics.last_month && e.analytics.last_month.fs_sessions}</td>
                                <td>{this.calculate_clicks(e['retainer'])}</td>
                                {this.state.available && <td>{`${this.calculate_budget(e['retainer'])}`}</td>}
                                <td className={'pbar'}
                                    width={200}>{true  &&
                                <ProgressBar
                                    label={`${overDone ? `100` : this.calculate_bar(e.analytics.this_month.fs_sessions, this.calculate_clicks(e['retainer']))}%`}
                                    striped animated variant="success" max={100}
                                    now={this.calculate_bar(e.analytics.this_month.fs_sessions, this.calculate_clicks(e['retainer']))}/>}
                                </td>
                                {/*<td>{this.dateToStr(e.status_changed[e.status_changed.length - 1].date)}</td>*/}
                                <td>{e.analytics.tracking_id}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <ToastContainer
                    position="bottom-center"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                />
            </div>
        )
    }

    dateToStr(date) {
        return date !== 0 ? new Date(date * 1000).toLocaleDateString('he-il', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }) : '-';
    }

    render() {
        return (
            <Col>
                {this.renderFilterForm()}
                {this.state.websitesPosts && this.renderTable()}
            </Col>
        );
    }
}

export default Plan;
