import React from "react";
import {IMAGES_PATH} from "../constants";
import {ClickHandler} from "./";

function RegularPost(e, query, page, data) {
// console.log(img)
    return (
        <div className='card'>
            <a
                href={e.url??`/post/${e.slug}${query}`}
                onClick={() => ClickHandler(e, query)}>
                <div className='card-body'>
                    <div className='img-container'
                         style={{backgroundImage: e.image? `url(${e.image})`: `url(${IMAGES_PATH}300/${e.website}-${e.slug}.jpg)`}}

                    />
                    <h5 className='card-title'>
                        {e.title}
                        <section className='arrow'>more</section>
                    </h5>
                    {/*<section className='views'>{e.views}</section>*/}
                    {page === 'website' && renderDate(e)}
                </div>
            </a>
        </div>
    )
}

function renderDate(e) {
    let date = e.date !== 0 ? new Date(e.date * 1000).toLocaleDateString() : '-';
    return (
        <section className='date'>{date}</section>
    )
}

export default RegularPost;
