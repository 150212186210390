import React from "react";
import {Col, Table} from "react-bootstrap";
import {PUSH_PATH} from "../../constants";

class EmailStats extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            activeOnly: true,
            websitesPosts: null,
            websites:[],
            posts: 0,
            fromDate: 0,
            toDate: 0,
            total_subs:0,
            yesterday:{
                'clicks':0,
                'views':0,
                'ctr':0,
            },
            sevendays:{
                'clicks':0,
                'views':0,
                'ctr':0,
            },
            thirtydays:{
                'clicks':0,
                'views':0,
                'ctr':0,
            }
        };
    }
    loadData() {
        fetch(PUSH_PATH+'v1/email-notifications', {
            headers: {'Content-Type': 'application/json', 'x-api-key': 'AIzaSyBMgXC9PTgSRLzuwYL3K8U5MqxzTwX4w1I'}
            })
            .then(response => response.json())
            .then(data => {
                if(data==undefined){
                    return
                }
                let yesterday = data.yesterday;
                let sevendays = data.sevendays;
                let thirtydays = data.thirtydays;
                let total_subs = data.total;
                let a = []
                
                let totalClicks = 0;
                let totalViews = 0;
                let totalSubs = 0;
                
                total_subs.forEach(domain=>{
                    if(a[domain._id]==undefined){
                        a[domain._id] = [];
                    }
                    if(a[domain._id]['total']==undefined){
                        a[domain._id]['total'] = 0;
                    }
                    a[domain._id]['total'] += domain.num_tutorial
                    totalSubs += domain.num_tutorial
                })
                this.setState({
                    total_subs: totalSubs,
                })
                yesterday.forEach(clicks=>{
                    if(a[clicks._id]==undefined){
                        a[clicks._id] = [];
                    }
                    if(a[clicks._id]['yesterday']==undefined){
                        a[clicks._id]['yesterday'] = [];
                        a[clicks._id]['yesterday']['clicks'] = 0;
                        a[clicks._id]['yesterday']['views'] = 0;
                    }
                    a[clicks._id]['yesterday']['clicks'] += clicks.clicks
                    a[clicks._id]['yesterday']['views'] += clicks.views
                    totalClicks += clicks.clicks
                    totalViews += clicks.views
                })
                this.setState({
                    yesterday:{
                        'clicks':totalClicks,
                        'views':totalViews,
                        'ctr':((totalClicks/totalViews)*100).toFixed(3),
                    },
                })
                
                totalClicks = 0;
                totalViews = 0;
                
                sevendays.forEach(clicks=>{
                    if(a[clicks._id]==undefined){
                        a[clicks._id] = [];
                    }
                    if(a[clicks._id]['sevendays']==undefined){
                        a[clicks._id]['sevendays'] = [];
                        a[clicks._id]['sevendays']['clicks'] = 0;
                        a[clicks._id]['sevendays']['views'] = 0;
                    }
                    a[clicks._id]['sevendays']['clicks'] += clicks.clicks
                    a[clicks._id]['sevendays']['views'] += clicks.views
                    totalClicks += clicks.clicks
                    totalViews += clicks.views
                })
                this.setState({
                    sevendays:{
                        'clicks':totalClicks,
                        'views':totalViews,
                        'ctr':((totalClicks/totalViews)*100).toFixed(3),
                    },
                });
                
                totalClicks = 0;
                totalViews = 0;
                
                thirtydays.forEach(clicks=>{
                    if(a[clicks._id]==undefined){
                        a[clicks._id] = [];
                    }
                    if(a[clicks._id]['thirtydays']==undefined){
                        a[clicks._id]['thirtydays'] = [];
                        a[clicks._id]['thirtydays']['clicks'] = 0;
                        a[clicks._id]['thirtydays']['views'] = 0;
                    }
                    a[clicks._id]['thirtydays']['clicks'] += clicks.clicks
                    a[clicks._id]['thirtydays']['views'] += clicks.views
                    totalClicks += clicks.clicks
                    totalViews += clicks.views
                });
                this.setState({
                    thirtydays:{
                        'clicks':totalClicks,
                        'views':totalViews,
                        'ctr':((totalClicks/totalViews)*100).toFixed(3),
                    },
                    websites: a
                });
            })
            .catch(err => console.error(this.props.url, err.toString()));
    }
    componentDidMount() {
        document.title = "ForeShop | Dashboard";
        this.loadData()
    }
    renderTable = ()=>{
        let tmp = [];
        Object.keys(this.state.websites).forEach((f,i)=>{
            let e = this.state.websites[f]
            tmp.push(
                <tr key={i} >
                    <td>{i+1}</td>
                    <td><a href={`/website/${f}`} target="_blank" rel="noopener noreferrer">{f}</a></td>
                    <td>{e.total}</td>
                    {(e.yesterday)?
                        <>
                            <td>{e.yesterday.views}</td>
                            <td>{e.yesterday.clicks}</td>
                            <td>{(e.yesterday.ctr)?"%":"0%"}</td>
                        </>
                        :
                        <>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                    }
                    {(e.sevendays)?
                        <>
                            <td>{e.sevendays.views}</td>
                            <td>{e.sevendays.clicks}</td>
                            <td>{(e.sevendays.ctr)?"%":"0%"}</td>
                        </>
                        :
                        <>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                    }
                    {(e.thirtydays)?
                        <>
                            <td>{e.thirtydays.views}</td>
                            <td>{e.thirtydays.clicks}</td>
                            <td>{(e.thirtydays.ctr)?"%":"0%"}</td>
                        </>
                        :
                        <>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                    }
                    
                </tr>
            )
        });
        return tmp;
    }
    renderData(){
        return (
            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th rowSpan={2}>#</th>
                    <th rowSpan={2}>Domain</th>
                    <th rowSpan={2}>Total Subsctibers({this.state.total_subs})</th>
                    <th colSpan={3}>Yesterday</th>
                    <th colSpan={3}>Last 7 Days</th>
                    <th colSpan={3}>Last 30 Days</th>
                </tr>
                <tr>
                    <th>Sent Count({this.state.yesterday.views})</th>    
                    <th>Clicks Count({this.state.yesterday.clicks})</th>    
                    <th>CTR({this.state.yesterday.ctr}%)</th>
                    <th>Sent Count({this.state.sevendays.views})</th>    
                    <th>Clicks Count({this.state.sevendays.clicks})</th>    
                    <th>CTR({this.state.sevendays.ctr}%)</th>
                    <th>Sent Count({this.state.thirtydays.views})</th>    
                    <th>Clicks Count({this.state.thirtydays.clicks})</th>    
                    <th>CTR({this.state.thirtydays.ctr}%)</th>
                </tr>
                </thead>
                <tbody>
                {this.renderTable()}
                </tbody>
            </Table>
        )
    }
    render() {
        return(
            <Col>
                {this.state.websites && this.renderData()}
            </Col>
        );
    }
}

export default EmailStats;