import React from "react";
import ClickHandler2 from './ClickHandler2';
function FirstPost2(e, query, page){
    return(
        <div id='first-card' className='card'>
            <a
                href={`#`}
                onClick={() => ClickHandler2(e, query)}
            >
                <div className='card-body'>
                    <div className='img-container'
                         style={{backgroundImage: `url(${e.image})`}}
                    />
                    <div className="card-title">
                        <h5>
                            {e.title}
                        </h5>
                    </div>
                    <div className="button">Read More</div>
                    {/*<section className='views'>{e.views}</section>*/}
                    { page === 'website' && renderDate(e) }
                </div>
            </a>
        </div>
    )
}
function renderDate(e){
    let date = e.date !== 0 ? new Date(e.date*1000).toLocaleDateString() : '-';
    return (
        <section className='date'>{date}</section>
    )
}
export default FirstPost2;
